import React from 'react';
import './stepper.scss';
import Wizard from 'App/components/Wizard';
import VStepper from './Stepper';
import { Container } from '@material-ui/core';
import BasicPriceDetailsWrapper from '../ComparePlans/Components/ProductPlans/BasicPriceDetailsWrapper';
import { AgilityGrid } from 'Src/AgilityComponents';
import i18n from 'i18next';
import { func, string, number, bool, object, array } from 'prop-types';
import { isEmpty, setSaveProgressData } from 'App/utils/helper';
import RouteLeavingDialog from 'App/utils/RouteLeavingDialog';
import {
  APPLICATION_ID,
  ACCOUNT_ID,
  SIGNUP_BY_SALE,
  SIGNUP_STEPS as signupSteps,
} from 'App/utils/constants';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  showPlanImage,
  showDirectDebitStep,
  showBillingStep,
  hasMobileAuthentication,
} from 'App/customConfig';
import { Helmet } from 'react-helmet';
import { withSnackbar } from 'notistack';
import cookie from 'js-cookie';

const titleTxt = 'Complete sign up';

const initialSteps = [
  {
    key: 0,
    nested: [
      {
        key: 1,
        title: `${i18n.t('stepper.selectedPlan.subtext')}`,
        id: signupSteps.selectedPlan,
        parent: 0,
        visible: true,
        component: '',
      },
    ],
    title: `${i18n.t('stepper.yourplan.text')}`,
    id: signupSteps.yourPlan,
    visible: true,
  },
  {
    key: 1,
    nested: [
      {
        id: signupSteps.yourCompanyDetails,
        key: 1,
        title: `${i18n.t('stepper.yourCompanyDetails.subtext')}`,

        parent: 1,
        visible: true,
      },
      {
        id: signupSteps.yourDetails,
        key: 2,
        title: `${i18n.t('stepper.yourDetails.subtext')}`,
        parent: 1,
        visible: true,
      },
      {
        id: signupSteps.mobileAuthentication,
        key: 5,
        title: `${i18n.t('stepper.mobileAuthentication.subtext')}`,
        parent: 1,
        visible: hasMobileAuthentication,
      },
      {
        id: signupSteps.yourProperty,
        key: 3,
        title: `${i18n.t('stepper.yourProperty.subtext')}`,
        parent: 1,
        visible: true,
      },
      {
        id: signupSteps.yourIdentification,
        key: 4,
        title: `${i18n.t('stepper.yourIdentification.subtext')}`,
        parent: 1,
        visible: true,
      },
    ],
    title: `${i18n.t('stepper.aboutplan.text')}`,
    id: signupSteps.aboutYou,
    visible: true,
  },
  {
    key: 2,
    nested: [
      {
        id: signupSteps.paymentMethod,
        key: 1,
        title: `${i18n.t('stepper.paymentMethod.subtext')}`,
        parent: 2,
        visible: showDirectDebitStep,
      },
      {
        id: signupSteps.paymentDetails,
        key: 2,
        title: `${
          showDirectDebitStep
            ? i18n.t('stepper.paymentDetails.subtext')
            : i18n.t('stepper.billingDetails.subtext')
        }`,
        parent: 2,
        visible: showBillingStep,
      },
    ],
    title: `${
      showDirectDebitStep
        ? i18n.t('stepper.paymentDetails.text')
        : i18n.t('stepper.billingDetails.text')
    }`,
    id: signupSteps.paymentDetails,
    visible: showDirectDebitStep || showBillingStep,
  },
  {
    key: 3,
    nested: [
      {
        id: signupSteps.lifeSupportConcession,
        key: 1,
        title: `${i18n.t('stepper.lifeSupportConcession.subtext')}`,
        parent: 3,
        visible: true,
      },
    ],
    title: `${i18n.t('stepper.lifeSupportConcession.text')}`,
    id: signupSteps.lifeSupportConcession,
    visible: true,
  },
  {
    key: 4,
    nested: [
      {
        id: signupSteps.completeSummary,
        key: 1,
        title: titleTxt,
        headerTitle: titleTxt,
        description: 'Check details and complete sign up',
        parent: 4,
        visible: true,
      },
    ],
    title: titleTxt,
    id: signupSteps.completeSummary,
    visible: true,
  },
];

class UserSignup extends React.Component {
  state = {
    isDirty: false,
    lastStep: '',
    steps: initialSteps,
    nextLoading: false,
    signupDataLoading: false,
    showDialog: false,
  };
  componentWillUnmount() {
    document.querySelector('.page-wrapper').className = 'page-wrapper';
  }
  getPricingComponent = () => {
    return (
      <BasicPriceDetailsWrapper
        {...this.props}
        signupAPIResponse={this.props.signupAPIResponse}
        productList={this.props.productList}
        selectedPlans={this.props.selectedPlans}
        showProductName={false}
        showImage={showPlanImage}
        showDesc={false}
        showIcon={true}
        isEmbeddedNetwork={this.props.isEmbeddedNetwork}
      />
    );
  };
  componentDidUpdate(prevProps) {
    if (this.props.selectedProperty !== prevProps.selectedProperty) {
      this.parseSteps();
    }
    if (
      this.props.match.params.step !== prevProps.match.params.step &&
      this.props.page <= this.props.completedStep
    ) {
      this.setCurrentStep();
    }
    if (
      this.props.selectedPlans !== prevProps.selectedPlans ||
      this.props.productList !== prevProps.productList
    ) {
      const steps = [...this.state.steps];
      steps[0].nested[0].component = (
        <BasicPriceDetailsWrapper
          {...this.props}
          signupAPIResponse={this.props.signupAPIResponse}
          productList={this.props.productList}
          selectedPlans={this.props.selectedPlans}
          showProductName={false}
          showImage={showPlanImage}
          showDesc={false}
          showIcon={true}
          inSidebar={true}
          isEmbeddedNetwork={this.props.isEmbeddedNetwork}
        />
      );
      this.setState({
        steps,
      });
    }
    if (
      this.props.triggerHideLeavePopup !== 0 &&
      this.props.triggerHideLeavePopup !== prevProps.triggerHideLeavePopup
    ) {
      this.setDialogState(false);
    }
  }

  fetchPrimarySiteIdentifier = () => {
    if (
      this.props.signupAPIResponse.services &&
      this.props.signupAPIResponse.services.length > 0
    ) {
      const { services, address } = this.props.signupAPIResponse;
      if (services.length > 0) {
        const [
          { productType = '', siteIdentifier = '' } = {
            siteIdentifier: '',
            productType: '',
          },
        ] = services;
        // const params = {
        //   productType: productType,
        //   siteIdentifier: siteIdentifier,
        //   hash: localStorage.getItem(APPLICATION_ID),
        //   account_id: localStorage.getItem(ACCOUNT_ID)
        // };
        if (address) {
          const data = {
            address,
            siteIdentifier,
            productType,
            formattedAddress: address.street_address,
            multilineAddress: (address.street_address || '').split(', '),
          };
          this.props.fetchSiteIdentifierDetailByID(data);
          this.requestCardList(address.State);
          this.props
            .fetchBillingFrequencyList({
              districtCode: address.State,
              customerTypeCode: this.props.signupAPIResponse.propertyType,
            })
            .catch(error => {
              if (error?.message) {
                this.props.enqueueSnackbar(error.message, { variant: 'error' });
              }
            });
        }
      }
    }
  };

  requestCardList = state => {
    // TODO request card types based on state
    const data = {
      state,
    };
    // const productType = services.filter(obj => obj.productType).map(obj => obj.productType);
    // params.productTypeCode = productType.length > 1 ? null : productType[0];
    this.props.fetchCardList(data).catch(error => {
      this.props.enqueueSnackbar(i18n.t('yourDetail.cardList.error'), {
        variant: 'error',
      });
    });
  };

  handleClick = (
    direction,
    data = {},
    fromSaveProgressDialog = false,
    loading = true
  ) => {
    if (direction === 'next' || direction === 'progress') {
      let apiCall;
      this.setState({
        isDirty: false,
      });
      if (!fromSaveProgressDialog && loading) {
        this.setState({
          nextLoading: true,
        });
      }
      data.id = localStorage.getItem(ACCOUNT_ID);
      data.hash = localStorage.getItem(APPLICATION_ID);

      const currentStepTitle = this.props.currentStep.title;
      let currentStepNumber = '';
      for (var i = 0; i < this.state.steps.length; i++) {
        for (const nestedStep of this.state.steps[i].nested) {
          if (nestedStep.title === currentStepTitle) {
            currentStepNumber = i + 1;
            break;
          }
        }
      }

      data.context = `Step ${currentStepNumber} - ${currentStepTitle}`;

      if (fromSaveProgressDialog) {
        data = setSaveProgressData(this.props.progressData, data);
        apiCall = this.props.postSignupDetails;
      } else {
        apiCall = this.props.putSignupDetails;
      }

      apiCall(data, fromSaveProgressDialog)
        .then(() => {
          if (!fromSaveProgressDialog || !loading) {
            this.setState({
              nextLoading: false,
            });
          } else {
            if (this.props.currentStep) {
              this.props.setCompleted(this.props.currentStep.key);
            }
          }

          const isSignupBySale =
            localStorage.getItem(SIGNUP_BY_SALE) ===
            localStorage.getItem(APPLICATION_ID);
          const isAgentView = !isEmpty(cookie.get('agentEnteredCode'));

          // the 2FA step isn't required for d2d sales
          if (
            data.currentPage === signupSteps.mobileAuthentication &&
            !(isSignupBySale || isAgentView)
          ) {
            this.props.requestMobileAuthentication(data);
          }
          if (direction === 'next') {
            this.props.handleClick(direction, this.state.steps, true);
          }
        })
        .catch(error => {
          this.props.enqueueSnackbar(i18n.t('500.error.message'), {
            variant: 'error',
          });

          if (!fromSaveProgressDialog || !loading) {
            this.setState({
              nextLoading: false,
            });
          }
          this.props.enqueueSnackbar(i18n.t('saveprogress.error.message'), {
            variant: 'error',
          });
        });
    } else if (direction === 'goto') {
      this.props.history.push('/user-signup/' + data.currentPage);
      this.setCurrentStep();
    } else {
      this.props.handleClick(direction, this.state.steps, true);
    }
  };

  parseSteps = () => {
    return new Promise((resolve, reject) => {
      const response = [...initialSteps];
      const final = [];
      let loop = 0;
      const { agentEnteredCode } = cookie.get();
      const isAgentView = !isEmpty(agentEnteredCode);
      const isSignupBySale =
        localStorage.getItem(SIGNUP_BY_SALE) ===
        localStorage.getItem(APPLICATION_ID);
      response.map((obj, index) => {
        // remove not visable parent steps
        if (!obj.visible) {
          return false;
        }
        if (obj.id === signupSteps.completeSummary && isAgentView) {
          return false;
        }
        const parentKey = loop;
        const data = { ...obj };
        data.nested = [];
        data.key = parentKey;

        if (obj.nested.length > 0) {
          obj.nested.map((child, index) => {
            if (child.visible) {
              let ignoreScreen =
                child.id === signupSteps.yourCompanyDetails &&
                this.props.selectedProperty === 'RESIDENT';
              ignoreScreen =
                ignoreScreen ||
                (child.id === signupSteps.paymentMethod && isAgentView);
              ignoreScreen =
                ignoreScreen ||
                (child.id === signupSteps.mobileAuthentication && isAgentView);
              ignoreScreen =
                ignoreScreen ||
                (child.id === signupSteps.mobileAuthentication &&
                  isSignupBySale);
              if (ignoreScreen) {
                // don't show on the stepers
              } else {
                const temp = { ...child };
                temp.key = loop;
                temp.parent = parentKey;
                if (child.id === signupSteps.selectedPlan) {
                  temp.component = this.getPricingComponent();
                }
                data.nested.push(temp);
                loop++;
              }
            }
            return data.nested;
          });
        } else {
          loop++;
        }
        final.push(data);
        return final;
      });
      this.setState({ steps: final }, () => {
        this.setCurrentStep();
        resolve();
      });
    });
  };

  getLastStep = () => {
    const lastObj = this.state.steps[this.state.steps.length - 1];
    if (lastObj.nested.length > 0) {
      this.setState({ lastStep: lastObj.nested[lastObj.nested.length - 1] });
    } else {
      this.setState({ lastStep: lastObj });
    }
  };

  callGetSignupDetails = () => {
    if (isEmpty(this.props.signupAPIResponse)) {
      this.setState(
        {
          signupDataLoading: true,
        },
        () => {
          this.props
            .getSignupDetails(
              localStorage.getItem(APPLICATION_ID),
              localStorage.getItem(ACCOUNT_ID)
            )
            .then(response => {
              this.setState({
                signupDataLoading: false,
              });
              this.parseSteps().then(() => {
                if (response.currentPage) {
                  const completedStepObj = this.getStepObject(
                    response.currentPage
                  );
                  if (completedStepObj) {
                    const childObj = completedStepObj.nested.find(
                      (obj, index) => {
                        return obj.id === response.currentPage;
                      }
                    );
                    this.props.setCompleted(childObj.key);
                  }
                }
                this.getLastStep();
                this.fetchPrimarySiteIdentifier();
                this.props
                  .fetchMoveInDatesList(localStorage.getItem(ACCOUNT_ID))
                  .catch(error => {
                    if (error?.message) {
                      this.props.enqueueSnackbar(error.message, {
                        variant: 'error',
                      });
                    }
                  });
                this.callGetPlansAPI();
              });
            })
            .catch(error => {
              this.setState({
                signupDataLoading: false,
              });
              this.props.enqueueSnackbar(i18n.t('signup.error.message'), {
                variant: 'error',
              });
            });
        }
      );
    } else {
      this.parseSteps().then(() => {
        this.props.setPropertyType(this.props.signupAPIResponse.propertyType);
        if (this.props.signupAPIResponse.currentPage) {
          const completedStepObj = this.getStepObject(
            this.props.signupAPIResponse.currentPage
          );
          if (completedStepObj) {
            const childObj = completedStepObj.nested.find((obj, index) => {
              return obj.id === this.props.signupAPIResponse.currentPage;
            });
            this.props.setCompleted(childObj.key);
          }
        }
        this.getLastStep();
        this.fetchPrimarySiteIdentifier();
        this.props
          .fetchMoveInDatesList(localStorage.getItem(ACCOUNT_ID))
          .catch(error => {
            if (error?.message) {
              this.props.enqueueSnackbar(error.message, { variant: 'error' });
            }
          });
        this.callGetPlansAPI();
      });
    }
  };
  callGetPlansAPI = () => {
    let services = [];
    if (this.props.signupAPIResponse.services) {
      services = [...this.props.signupAPIResponse.services];
    }

    if (services && services.length > 0) {
      Object.keys(services).forEach(key => {
        if (!this.props.selectedPlans[services[key].productType]) {
          this.props
            .getPlanDetailsAPI(
              localStorage.getItem(APPLICATION_ID),
              localStorage.getItem(ACCOUNT_ID),
              services[key].productType,
              services[key].id
            )
            .catch(error =>
              this.props.enqueueSnackbar(i18n.t('plandetails.fetch.error'), {
                variant: 'error',
              })
            );
        }
      });
    }
  };
  componentDidMount() {
    const appId = localStorage.getItem(APPLICATION_ID);
    if (appId === undefined || appId === null || appId === '') {
      this.props.history.push('/');
      return;
    }
    this.callGetSignupDetails();

    document.querySelector('.page-wrapper').className =
      'page-wrapper userSignUp-page';
    this.props.history.listen((location, action) => {
      // location is an object like window.location
      if (action === 'POP') {
        this.props.history.push('/');
      }
    });
    this.setState({
      showDialog: true,
    });
    window.onbeforeunload = () => true;
  }

  setDialogState = flag => {
    this.setState({
      showDialog: flag,
    });
  };

  setCurrentStep = () => {
    const data = this.getStepObject(this.props.match.params.step);
    if (data) {
      const childObj = data.nested.find((obj, index) => {
        return obj.id === this.props.match.params.step;
      });
      this.props.setCurrentStep(childObj);
      const search = this.props.location.search
        ? this.props.location.search
        : '';
      let pushUrl = `/user-signup/${this.props.match.params.step}`;
      if (search) pushUrl += search;
      this.props.history.push({
        pathname: pushUrl,
        search: search,
      });
    } else {
      this.props.history.push(`/404`);
    }
  };

  getStepObject = inputId => {
    const data = this.state.steps.find(({ nested }) =>
      nested.some(obj => obj.id === inputId)
    );
    return data;
  };

  render() {
    return (
      <React.Fragment>
        {!isEmpty(this.props.signupAPIResponse) && (
          <section
            className="page-content steps-container"
            data-test-id="signup-wrapper"
          >
            <Helmet>
              <title>{`${this.props.currentStep.title} | ${process.env.REACT_APP_RETAILER_NAME}`}</title>
            </Helmet>
            <Container>
              <AgilityGrid
                container
                justifyContent="center"
                alignItems="center"
              >
                <AgilityGrid item xs={12} sm={12} md={12} lg={10}>
                  <AgilityGrid container className="content">
                    <AgilityGrid item xs={12} sm={12} md={8}>
                      <Wizard
                        {...this.props}
                        steps={this.state.steps}
                        page={this.props.page}
                        signupDataLoading={this.state.signupDataLoading}
                        currentStep={this.props.currentStep}
                        onNavigate={this.handleClick}
                        nextLoading={this.state.nextLoading}
                        signupAPIResponse={this.props.signupAPIResponse}
                        saveProgressTrigger={this.props.saveProgressTrigger}
                        primarySiteIdentifier={this.props.primarySiteIdentifier}
                        isEmbeddedNetwork={this.props.isEmbeddedNetwork}
                      />
                    </AgilityGrid>
                    <AgilityGrid item xs={12} sm={12} md={4}>
                      <VStepper
                        {...this.props}
                        activeStep={this.props.page}
                        steps={this.state.steps}
                        totalSteps={this.state.lastStep.key}
                        currentStep={this.props.currentStep.parent}
                      />
                    </AgilityGrid>
                  </AgilityGrid>
                </AgilityGrid>
              </AgilityGrid>
            </Container>
          </section>
        )}
        <RouteLeavingDialog
          // When should shouldBlockNavigation be invoked,
          // simply passing a boolean
          // (same as "when" prop of Prompt of React-Router)
          when={this.state.showDialog}
          // Navigate function
          navigate={path => this.props.history.push(path)}
          // Use as "message" prop of Prompt of React-Router
          shouldBlockNavigation={location => {
            // Blocks user only when user clicks the logo to leave the page.
            if (
              location.pathname.includes('user-signup') === false &&
              location.pathname !== '/404' &&
              location.pathname !== '/signup-completion'
            ) {
              return true;
            }
            return false;
          }}
        />
        <Backdrop
          open={this.state.signupDataLoading}
          className="full-page-loader"
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment>
    );
  }
}

UserSignup.propTypes = {
  putSignupDetails: func,
  emailId: string,
  triggerHideLeavePopup: number,
  isEmbeddedNetwork: bool,
  signupAPIResponse: object,
  productList: array,
  selectedPlans: object,
  selectedProperty: string,
  completedStep: number,
  step: number,
  currentStep: object,
};

UserSignup.defaultProps = {
  emailId: '',
  triggerHideLeavePopup: 0,
  isEmbeddedNetwork: false,
  signupAPIResponse: {},
  productList: [],
  selectedPlans: {},
  selectedProperty: 'RESIDENT',
  completedStep: 0,
  step: 0,
  currentStep: {},
};

export { UserSignup };

export default withSnackbar(UserSignup);
